import { forwardRef, useRef } from "react"
import { composeRefs } from "@radix-ui/react-compose-refs"

import { cn } from "@/lib/utils/classnames"

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, children, ...props }, forwardedRef) => {
		const inputRef = useRef<HTMLInputElement>(null)
		return (
			<div
				className={cn(
					"flex h-9 w-full cursor-text items-center rounded-md border border-input bg-background text-sm shadow-sm transition-colors",
					// if the input is focused
					"has-[[data-input]:focus]:outline has-[[data-input]:focus]:outline-2 has-[[data-input]:focus]:outline-focus",
					// or disabled
					"has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-50",
					className,
				)}
				onPointerDown={(event) => {
					const target = event.target as HTMLElement
					if (target.closest("input, button, a")) return

					const input = inputRef.current
					if (!input) return

					const isEndSlot = target.closest(`
						[data-input-slot][data-side='end'],
						[data-input-slot]:not([data-side='end']) ~ [data-input-slot]:not([data-side='start'])
					`)

					const cursorPosition = isEndSlot ? input.value.length : 0

					requestAnimationFrame(() => {
						// Only some input types support this, browsers will throw an error if not supported
						// See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#:~:text=Note%20that%20according,not%20support%20selection%22.
						try {
							input.setSelectionRange(
								cursorPosition,
								cursorPosition,
							)
							// eslint-disable-next-line no-empty
						} catch {}
						input.focus()
					})
				}}
			>
				<input
					// eslint-disable-next-line react-compiler/react-compiler
					ref={composeRefs(inputRef, forwardedRef)}
					data-input
					spellCheck="false"
					className={cn(
						"size-full border-0 bg-transparent px-3 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-foreground-weaker disabled:cursor-not-allowed",
						// remove search cancel button (optional)
						"[&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden",
						// hide the number input spin buttons
						"[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
					)}
					{...props}
				/>
				{children}
			</div>
		)
	},
)
Input.displayName = "Input"

type InputSlotElement = React.ElementRef<"div">
interface InputSlotProps extends React.InputHTMLAttributes<HTMLDivElement> {
	side?: "start" | "end"
}
const InputSlot = forwardRef<InputSlotElement, InputSlotProps>(
	({ className, side = "start", children, ...props }, forwardedRef) => {
		return (
			<div
				ref={forwardedRef}
				data-input-slot
				data-side={side}
				className={cn(
					"text-foreground-weaker",
					side === "start" && "order-first ps-3",
					side === "end" && "pe-3",
					"[&_button]:size-7 [&_button]:border-0 [&_button]:!bg-transparent [&_button]:p-0 [&_button]:shadow-transparent [&_button]:hover:text-foreground [&_button_svg]:size-4 [&_svg]:size-4",
					className,
				)}
				{...props}
			>
				{children}
			</div>
		)
	},
)
InputSlot.displayName = "InputSlot"

export { Input, InputSlot }
